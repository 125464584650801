/* FAQ.js */
.faq-title {
  text-align: center;
  margin-bottom: 30px;
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
}

.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 50px;
  justify-content: center;
  margin-bottom: 50px;
}

.accordion-item {
  margin-bottom: 1rem;
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
}

.accordion-header,
.accordion-body {
  width: 100%;
}

@media (max-width: 1024px) {
  .faq-container {
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 0 10px;
    max-width: 95%;
  }
  .accordion-item {
    font-size: 14px;
  }
  .logo {
    width: 120px;
  }
}
