/* Footer.js */
.ganpan {
  width: 130px;
  border-radius: 30%;
  display: block;
  margin: 0 auto;
}

.footer-container {
  padding: 20px;
}

.footer-title {
  font-size: 24px;
}

.footer-link {
  font-size: 16px;
}
.naver-icon {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

/* ========================================================================== */
@media (max-width: 992px) {
  .footer-title {
    font-size: 20px;
  }

  .ganpan {
    width: 30%;
  }

  .footer-link {
    font-size: 12px;
  }

  .contact-details {
    font-size: 18px;
  }
}

/* ============================================================================ */
@media (max-width: 768px) {
  .footer-title {
    font-size: 18px;
  }

  .ganpan {
    width: 60%;
  }

  .text-uppercase {
    font-size: 14px;
  }

  .footer-content {
    font-size: 14px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .contact-details {
    font-size: 1rem;
  }

  .naver-icon {
    width: 30px;
    height: 30px;
  }
}

/* ========================================================================== */
@media (max-width: 576px) {
  .footer-title {
    font-size: 16px;
  }

  .ganpan {
    width: 30%;
  }

  .text-uppercase {
    font-size: 12px;
  }

  .content {
    font-size: 14px;
  }

  .text-reset {
    font-size: 10px;
  }

  .footer-link {
    font-size: 13px;
  }

  .instaIcon {
    width: 30px;
    height: auto;
  }

  .footer-container {
    padding: 10px;
  }

  .contact-details {
    font-size: 0.8rem;
  }
}
