.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  margin: 0 auto;
  width: 80%;
}

.slide-container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.slide-item {
  position: relative;
  padding: 0 15px;
}

.slide-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
  outline: 2px solid #000;
  outline-offset: -5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.location-dot {
  margin-right: 10px;
}
.contact-title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.googleMap-container {
  text-align: center;
  width: 67%;
  height: 600px;
  margin: 20px 0;
}

.consultation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: row;
  width: 50%;
  margin: 40px auto 10px;
  gap: 30px;
}

.consultation-left,
.consultation-right {
  flex: 1;
  min-width: 300px;
}

.consultation-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consultation-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.kakao-link {
  display: flex;
  align-items: center;
}

.home-tradingHours-time {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.icon {
  width: 50px;
  height: 50px;
  border-color: #e5e7eb;
  margin-left: 5px;
}

.address {
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  margin-top: 20px;
}

.contact-phone {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* ============================================================================================== */
@media (max-width: 768px) {
  .slide-image {
    width: 100%;
    outline: 1px solid #000;
    margin: 10px 10px;
  }

  .googleMap-container {
    width: 90%;
    height: 700px;
    margin-top: 0;
  }

  .chaeulmi-address {
    font-size: 16px;
  }

  .contact-title {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .consultation {
    width: 80%;
    margin: 10px auto 10px;
    gap: 0px;
  }

  .consultation-left {
    flex: 1;
    min-width: 180px;
    margin-left: 20px;
    align-items: center;
  }

  .consultation-right {
    flex: 2;
    min-width: 80%;
    margin-left: 10px;
  }

  .consultation-right {
    gap: 0px;
  }

  .texts,
  .contact-phone {
    font-size: 16px;
    margin-bottom: 0;
  }
}
/* ============================================================================================== */
@media (max-width: 576px) {
  .slide-image {
    height: 100px;
    border: 2px solid #ddd;
  }

  .chaeulmi-address {
    font-size: 14px;
  }

  .googleMap-container {
    width: 80%;
    height: 120px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .address {
    font-size: 14px;
  }

  .consultation {
    width: 100%;
  }

  .consultation-left {
    min-width: 80px;
  }
  .consultation-right {
    font-size: 14px;
  }

  .texts,
  .contact-phone,
  .kakaotalk {
    font-size: 14px;
    margin-bottom: -20px;
  }
}
