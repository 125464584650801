/* Review.js */
.review-container {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.review-row {
  margin-top: 30px;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.review-images {
  width: 100%;
  max-width: 500px;
  height: auto;
  display: flex;
  justify-content: center;
}

.review-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.review-col {
  flex: 0 0 auto;
  width: 25%;
}

.zoomed-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  cursor: pointer;
}

.zoomed-image {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 10px;
}

/* Review-phone.js */
.review-valuable {
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
  font-weight: bold;
}

.rating {
  display: flex;
  justify-content: center;
}

.rating-number {
  margin-right: 10px;
}

.star-icon {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  color: gold;
  font-size: 25px;
  margin: 0 2px;
  margin-bottom: 30px;
}

.review-phone {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: 60px;
  text-align: center;
}

.phone {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: none;
}

/* Add these classes for swipe animations */
.swipe-area.swipe-left {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.swipe-area.swipe-right {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.phone-frame {
  transition: transform 0.3s ease-in-out;
}

.slide {
  position: absolute;
  width: 88%;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  color: black;
  box-shadow: 0px 0px 10px white;
  cursor: pointer;
}

.arrow-left {
  left: -3rem;
}

.arrow-right {
  right: -3rem;
}

.indicators {
  display: none;
}

.indicator {
  background-color: black;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  margin: 0 0.2rem;
}

.indicator-inactive {
  background-color: gray;
}

/* =================================================================== */

@media (max-width: 768px) {
  .review-phone {
    max-width: 70%;
    padding: 0 5px;
  }

  .review-valuable,
  .star-icon {
    font-size: 14px;
  }

  .rating-number {
    font-size: 16px;
  }

  .star-icon {
    margin-bottom: 20px;
  }

  .swipe-gesture {
    position: absolute;
    bottom: 0% !important;
    left: 50%;
    max-width: 40%;
  }

  .review-phone-images {
    width: 20%;
  }

  .slide {
    width: 89%;
    top: 55%;
  }

  .arrow-left {
    left: -2rem;
  }

  .arrow-right {
    right: -2rem;
  }
  .indicators {
    display: none;
  }
}

/* =================================================================== */
@media (max-width: 480px) {
  .swipe-gesture {
    max-width: 50%;
  }

  .indicators {
    bottom: 0.3rem;
  }

  .indicator {
    display: none;
  }
}
