.text-container {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 70px;
}

.gallery-text {
  font-size: 25px;
  font-weight: bold;
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
}

.custom-tabs {
  width: 100%;
  overflow-x: auto;
  margin: 10px auto;
  white-space: nowrap;
}

.custom-tabs .nav-link {
  display: flex;
  white-space: nowrap;
  font-size: 1.3rem;
  padding: 10px;
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.gallery-wrapper {
  padding: 15px;
  width: calc(25% - 10px);
  box-sizing: border-box;
}

.gallery-image {
  width: 100%;
  height: 430px;
  object-fit: cover;
  border-radius: 10%;
}

.zoomed-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.zoomed-image {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.zoomed-button {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.zoomed-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.zoomed-button-prev {
  left: 10px;
}

.zoomed-button-next {
  right: 30px;
}

.zoomed-button-close {
  top: 30px;
  right: 30px;
  color: black;
}

/* ============================================================================================== */
@media (max-width: 768px) {
  .text-container {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  .gallery-wrapper {
    width: calc(50% - 10px);
  }

  .gallery-image {
    max-height: 300px;
  }
  .gallery-text {
    font-size: 20px;
  }
  .custom-tabs .nav-link {
    margin: 0px 0px;
  }

  .custom-tabs {
    margin-left: 5px;
  }

  .zoomed-button-next {
    right: 10px;
  }

  .zoomed-button-close {
    top: 100px;
    right: 30px;
    color: black;
  }
}

/* ============================================================================================== */
@media (max-width: 576px) {
  .gallery-text {
    font-size: 14px;
  }

  .gallery-wrapper {
    width: calc(50% - 10px);
    padding: 5px;
  }

  .gallery-image {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }

  .zoomed-image {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }

  .custom-tabs .nav-link {
    font-size: 0.75rem;
    padding: 4px;
  }
  .zoomed-button-prev,
  .zoomed-button-next {
    display: none;
  }
}
