.home-section {
  padding: 0 15px;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
}

.homepage-wrapper {
  position: relative;
  width: 100%;
}

.homepage {
  width: 100%;
  height: auto;
  border-radius: 3rem;
}

.homepage-text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.homepage-text2 {
  position: absolute;
  bottom: 10%;
  width: 100%;
  text-align: center;
  color: white;
  padding: 0 15px;
}

.homepage-text2 h3 {
  margin-bottom: 10px;
}

.homepage-text h1,
.homepage-text p {
  margin: 0;
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
}

.home-button {
  margin-left: 20px;
  font-size: 20px !important;
}

.mikyung-container {
  padding: 0 15px;
  margin-top: 50px;
}

.mikyung-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 40px;
}

.mikyung {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 2rem;
  object-fit: contain;
  margin-right: 15px;
  overflow: hidden;
}

.title {
  flex: 1;
}

.about {
  text-align: center;
}

.introduction {
  margin-bottom: 20px;
  text-align: center;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.list {
  flex: 1;
  min-width: 200px;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: calc(33% - 40px);
}

.list-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.lists {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
  text-align: left;
  margin-right: 20px;
}

.list-item {
  margin-bottom: 8px;
}

.message {
  max-width: 800px;
  margin-top: 50px;
}

.facility-section {
  margin-top: 50px;
}
.facility-title {
  text-align: center;
  margin-bottom: 40px;
  font-family: "Noto Sans KR", "Nanum Gothic", "Malgun Gothic", sans-serif;
}

.facility-container {
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.facility-image-wrapper {
  width: calc(33.33% - 10px);
  margin-bottom: 20px;
}

.facility-images {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}

.zoomed-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  cursor: pointer;
  padding: 10px;
}

.zoomed-image {
  width: auto;
  height: auto;
  max-width: 90vw;
  height: 80vh;
  object-fit: contain;
  border-radius: 10px;
}

.section-container {
  padding: 20px;
  width: 30%;
  margin: 0 auto;
}

.trading-hour {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-align: center;
}

.trading-hour-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.trading-hour-left {
  flex: 1;
}

.trading-hour-right {
  flex: 2;
  text-align: left;
}

.trading-hour-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.bottom-button {
  font-size: 20px !important;
  width: 120%;
}

.icon {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.instaIcon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  margin-bottom: -2px;
}

/* ============================================================================================== */
@media (max-width: 768px) {
  .homepage {
    border-radius: 10%;
  }

  .homepage-text h1 {
    font-size: 1.5rem;
    padding: 0 10px;
    top: 50%;
  }

  .home-button,
  .bottom-button {
    font-size: 14px !important;
  }

  .about {
    text-align: center;
  }

  .title {
    margin-top: 0px;
    margin-left: 0px;
  }

  .mikyung-container {
    margin-top: 30px;
  }

  .mikyung-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .mikyung {
    max-width: 80%;
    border-radius: 2rem;
    margin-right: 0px;
    height: auto;
  }

  .introduction {
    text-shadow: 2rem;
  }
  .introduction,
  .introduction-mikyung {
    font-size: 1rem;
  }

  .list-container {
    gap: 15px;
  }

  .list {
    max-width: 100%;
  }

  .lists {
    padding-left: 15px;
  }

  .list-item {
    font-size: 0.9rem;
  }
  .message {
    font-size: 1rem;
    margin-top: 10px;
    justify-content: space-between;
  }

  .facility-title {
    text-align: center;
    margin-bottom: 0x;
  }

  .facility-image-wrapper {
    width: calc(50% - 10px);
    margin-bottom: 20px;
  }

  .facility-images {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  .zoomed-image {
    max-width: 90vw;
    max-height: 80vh;
  }

  .section-container {
    width: 100%;
    padding: 10px;
  }

  .trading-hour-left {
    flex: 0.5;
  }

  .trading-hour-right {
    flex: 2;
    text-align: left;
    padding-left: 20px;
  }

  .trading-hour-text {
    font-size: 0.78rem !important;
    margin-left: 30px;
  }

  .trading-hour-button {
    margin-top: 0;
  }
}

/* ============================================================================================== */
@media (max-width: 576px) {
  .homepage-text {
    font-size: 1.5rem;
    top: 25%;
  }

  .homepage-text2,
  .introduction-mikyung {
    bottom: 1%;
    font-size: 1rem;
  }

  .home-button {
    font-size: 12px;
    margin-top: 10px;
  }

  .mikyung-content {
    margin-top: 20px;
  }

  .about,
  .facility-title {
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .mikyung {
    max-width: 60%;
    border-radius: 1rem;
  }

  .introduction {
    font-size: 0.9rem;
  }

  .introduction-mikyung {
    font-size: 0.9rem;
  }

  .list-container {
    gap: 10px;
  }

  .list {
    max-width: 100%;
  }

  .lists {
    padding-left: 10px;
  }

  .list-item {
    font-size: 0.8rem;
  }

  .message {
    margin-top: 40px;
    font-size: 0.9rem;
    padding: 0 10px;
  }

  .facility-image-wrapper {
    width: calc(50% - 10px);
  }

  .facility-images {
    height: 150px;
  }

  .zoomed-image {
    max-width: 90vw;
    max-height: 80vh;
  }

  .trading-hour-text {
    font-size: 0.875rem;
  }
}
